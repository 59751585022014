<template>
  <a href="https://wa.me/972546699285?text=היי, אשמח לשמוע פרטים">
    <img class="whatsapp" src="../assets/img/whatsapp.png" alt="">
  </a>
</template>

<script>
export default {
  name: 'WhatsApp',
}
</script>

<style></style>
